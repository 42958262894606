import React from 'react';
import { graphql } from 'gatsby';

import ParagraphComponent from '@core/helpers/paragraph-helpers';
import { isContentTypeHero } from '@core/helpers/hero';

import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';

import { Graphql } from '@models/graphql';

const { services: crumbs } = require('@config/breadcrumbs');

type ServiceProps = {
  language: string,
  data: {
    service: Graphql
  };
};

export default function ServicePage(
  { data, language }: ServiceProps
) : React.ReactElement {
  const { service } = data;
  const paragraphs = service?.relationships?.paragraphs;

  if (!paragraphs || paragraphs.length <= 0) {
    return <></>;
  }

  const content = paragraphs.map(
    (node) => ParagraphComponent({ ...node, id: node.id, path: service.path }, language)
  );

  const hero = isContentTypeHero(paragraphs[0]) ? content.shift() : <></>;

  return (
    <>
      {hero}
      <Breadcrumb crumbs={crumbs.concat({ label: service.title, link: '#' })} />
      <div className="page-components-container">{content}</div>
    </>
  );
}

export const query = graphql`
query($id: String!) {
  service: nodeService(id: { eq: $id }) {
    id
    title
    body {
      processed
      summary
    }
    path {
      alias
      langcode
    }
    relationships {
      paragraphs: field_content_main {
        type: __typename
        ...CtaParagraph
        ...HeroParagraph
        ...HeroSliderParagraph
        ...BannerParagraph
        ...BannerTalentParagraph
        ...BannerAdvParagraph
        ...QuoteSliderParagraph
        ...QuoteParagraph
        ...IntroTextParagraph
        ...IntroBlockParagraph
        ...TimelineParagraph
        ...CardsParagraph
        ...BoxesParagraph
        ...GridParagraph
        ...ClientsParagraph
        ...TechnologiesParagraph
        ...TabsParagraph
        ...TableParagraph
        ...WysiwygParagraph
        ...ViewParagraph
        ...FeaturedContentParagraph
        ...BulletsParagraph
        ...BannerFormParagraph
        ...BannerTalentApplyParagraph
        ...EmergencyModalParagraph
        ...BannerVideoParagraph
        ...WebFormParagraph
        ...CollageParagraph
        ...FabParagraph
        ...ImageSliderParagraph
        ...OfficesParagraph
      }
    }
  }
}`;
